import { useContext } from "react";
import { PageContext } from "./PageProvider";

export const usePage = () => {
  const values = useContext(PageContext);

  if (values === null) throw new Error("no page context");

  return values;
};

export const useTranslations = () => usePage().translations;

export const useImages = () => usePage().images;
