import { navigateTo } from "@src/utils/location";
import { logEvent } from "@src/utils/logClient";
import { getRelativePath } from "@src/utils/routes";
import { useCallback, useMemo } from "react";

export type ActionReturn = ReturnType<typeof useActions>;

export const useActions = () => {
  const le = useCallback(
    (data: string) =>
      logEvent({
        eventName: `web.external_page.new-mentor.${data}`,
        eventValue: window.location.href,
      }),
    [],
  );
  const goToApply = useCallback(() => navigateTo(getRelativePath("/become-mentor-application")), []);

  const actions = useMemo(
    () => ({
      pageView: () => {
        le("page_view");
      },
      apply_top_hero_button: () => {
        le("top_hero_apply_click");
        goToApply();
      },
      mentor_numbers_apply_click: () => {
        le("mentor_numbers_apply_click");
        goToApply();
      },
      you_might_be_a_mentor_apply_click: () => {
        le("you_might_be_a_mentor_apply_click");
        goToApply();
      },
      make_a_difference_apply_click: () => {
        le("make_a_difference_apply_click");
        goToApply();
      },
      mentor_access: () => {
        le("mentor_access_click");
        goToApply();
      },
    }),
    [goToApply, le],
  );

  return actions;
};
