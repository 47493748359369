import { forEach, isEmpty } from "lodash";
// import { faker } from "@faker-js/faker";
import { useGetStaticAssetsQuery } from "./useGetStaticAssetsQuery";

export type ImageReturn = ReturnType<typeof useImages>;

const BG = "#FFC0CB";

const fakeItUntilYouMakeIt = (obj: Record<string, { publicURL: string }>, verify = false) => {
  forEach(obj, (v, k) => {
    if (isEmpty(v.publicURL)) {
      if (verify) {
        throw new Error("no publicURL for " + k);
      }
      // obj[k].publicURL = faker.image.urlPlaceholder({ width: 2200, height: 1200, backgroundColor: BG, text: k });
    }
  });
};

const mapAssetsToValues = (
  values: Record<string, { publicURL: string }>,
  assets: Record<string, { publicURL: string }>,
) => {
  forEach(values, (v, k) => {
    if (isEmpty(v.publicURL)) {
      const asset = assets[k];
      if (asset) {
        values[k] = asset;
      }
    }
  });
};

export const useImages = () => {
  const assets = useGetStaticAssetsQuery();

  const values = {
    apply_top_hero_image: { publicURL: "" },
    apply_top_hero_badge: { publicURL: "" },

    get_the_good_image: { publicURL: "" },
    you_might_be_a_mentor_1_image: { publicURL: "" },
    you_might_be_a_mentor_2_image: { publicURL: "" },
    you_might_be_a_mentor_3_image: { publicURL: "" },

    description_panel_1_image: { publicURL: "" },
    description_panel_2_image: { publicURL: "" },
    description_panel_3_image: { publicURL: "" },

    make_a_difference_bg_image: { publicURL: "" },
    mentor_numbers_bg_image: { publicURL: "" },
  };

  mapAssetsToValues(values, assets);
  fakeItUntilYouMakeIt(values, false);

  return values;
};
