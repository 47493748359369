import React from "react";
import { GQLImage } from "@src/components/whats-new-sl/context";
import { createContext, ReactNode } from "react";

export interface PageContextProps {
  translations: Record<string, string>;
  images: Record<string, GQLImage>;
}

export const PageContext = createContext<PageContextProps | null>(null);

export interface PageProviderProps {
  translations: Record<string, string>;
  images: Record<string, GQLImage>;
  children: ReactNode;
}

export const PageProvider = ({ translations, images, children }: PageProviderProps) => {
  return <PageContext.Provider value={{ translations, images }}>{children}</PageContext.Provider>;
};
