import { useStaticQuery, graphql } from "gatsby";

const GQL_ASSETS = graphql`
  {
    apply_top_hero_image: file(relativePath: { eq: "new-mentors/apply_top_hero_image.gif" }) {
      publicURL
    }
    apply_top_hero_badge: file(relativePath: { eq: "new-mentors/apply_top_hero_badge.svg" }) {
      publicURL
    }
    you_might_be_a_mentor_1_image: file(relativePath: { eq: "new-mentors/you_might_be_a_mentor_1_image.gif" }) {
      publicURL
    }
    you_might_be_a_mentor_2_image: file(relativePath: { eq: "new-mentors/you_might_be_a_mentor_2_image.gif" }) {
      publicURL
    }
    you_might_be_a_mentor_3_image: file(relativePath: { eq: "new-mentors/you_might_be_a_mentor_3_image.gif" }) {
      publicURL
    }

    description_panel_1_image: file(relativePath: { eq: "new-mentors/description_panel_1_image.png" }) {
      publicURL
    }
    description_panel_2_image: file(relativePath: { eq: "new-mentors/description_panel_2_image.png" }) {
      publicURL
    }
    description_panel_3_image: file(relativePath: { eq: "new-mentors/description_panel_3_image.png" }) {
      publicURL
    }

    make_a_difference_bg_image: file(relativePath: { eq: "new-mentors/make_a_difference_bg_image.gif" }) {
      publicURL
    }

    get_the_good_image: file(relativePath: { eq: "new-mentors/get_the_good_image.png" }) {
      publicURL
    }
    mentor_numbers_bg_image: file(relativePath: { eq: "new-mentors/mentor_numbers_bg_image.png" }) {
      publicURL
    }
  }
`;

export const useGetStaticAssetsQuery = () => useStaticQuery(GQL_ASSETS);
