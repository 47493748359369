import React, { CSSProperties } from "react";
import {
  BodyVariants,
  DisplayVariants,
  HeadlineVariants,
  LabelVariants,
  Typography,
  TypographyProps,
} from "@src/components/Typography";
import { Button } from "@src/components/nessie-web";
import { GImage } from "@src/components/GImage";
import { Interpolation, Theme } from "@emotion/react";
import { Flex } from "@src/components/Boxes";
import { ButtonProps } from "./utils";
import { GQLImage } from "@src/components/whats-new-sl/context";
import styled from "@emotion/styled";

export type HasValue = { value: string };

export type TypographyExtended<T> = Omit<TypographyProps, "children" | "variant"> & { variant: T } & HasValue;

export const Label = ({ value, variant, ...props }: TypographyExtended<LabelVariants>) => (
  <Typography variant={variant} {...props}>
    {value}
  </Typography>
);

export const Body = ({ value, variant, ...props }: TypographyExtended<BodyVariants>) => (
  <Typography variant={`Body${variant}`} {...props}>
    {value}
  </Typography>
);

export const Display = ({ value, variant, ...props }: TypographyExtended<DisplayVariants>) => (
  <Typography variant={`Display${variant}`} {...props}>
    {value}
  </Typography>
);

export const Headline = ({ value, variant, ...props }: TypographyExtended<HeadlineVariants>) => (
  <Typography variant={`Headline${variant}`} {...props}>
    {value}
  </Typography>
);

/**
 *
 *
 *
 *
 */

type SecondaryButtonProps = ButtonProps & { value: string };

export const SecondaryButton = ({ value, ...props }: SecondaryButtonProps) => <Button {...props}>{value}</Button>;

type PrimaryButtonProps = ButtonProps & { value: string };

const ButtonStyled = styled(Button)`
  background-color: ${(props) => props.theme.__new.colors.contentAccent};
`;

export const PrimaryButton = ({ value, ...props }: PrimaryButtonProps) => (
  <ButtonStyled {...props}>{value}</ButtonStyled>
);

/**
 *
 *
 *
 *
 */

export type ImageProps = { img: GQLImage } & Omit<React.ComponentProps<typeof GImage>, "img"> &
  Pick<CSSProperties, "width" | "height">;

export const Image = ({ img, alt, css, width, height, ...props }: ImageProps) => {
  img = img ?? { publicURL: "https://placehold.co/600x400" };

  return <GImage img={img} alt={alt} css={[{ width, height }, css]} {...props} />;
};

type FlexBackgroundProps = React.ComponentProps<typeof Flex> & {
  img: GQLImage;
  css?: Interpolation<Theme>;
} & Pick<CSSProperties, "backgroundPosition" | "backgroundSize" | "backgroundColor">;

export const FlexBackground = ({
  css,
  img,
  backgroundColor,
  backgroundPosition,
  backgroundSize,
  ...props
}: FlexBackgroundProps) => {
  img = img ?? { publicURL: "https://placehold.co/600x400" };
  return (
    <Flex
      {...props}
      css={[css, { backgroundColor, backgroundPosition, backgroundSize, backgroundImage: `url(${img?.publicURL})` }]}
    />
  );
};
