// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconVolumnOff = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2089 15.6826C15.3139 15.7651 15.3701 15.8963 15.3514 16.0276C15.2351 16.9163 15.0851 17.7976 14.8939 18.6751C14.7551 19.3163 14.0576 19.6688 13.4576 19.3988C11.2826 18.5213 9.39639 17.0588 8.00139 15.1726L7.85139 14.9663C7.76889 14.8688 7.63764 14.8238 7.51014 14.8538L7.48764 14.8613C6.76764 15.0526 6.01389 15.0788 5.28264 14.9326C4.94889 14.8651 4.67514 14.6363 4.55514 14.3101L4.54389 14.2838C4.00389 12.8288 3.99639 11.2276 4.51389 9.76509L4.53264 9.71634C4.65264 9.37884 4.94139 9.12759 5.30889 9.05634L5.35389 9.04884C5.73639 8.97384 6.12639 8.95509 6.51639 8.97759C6.59139 8.98134 6.66639 9.01134 6.72639 9.06009L15.2089 15.6826ZM19.4801 15.5213L15.7526 12.6113C15.6626 12.5401 15.6101 12.4313 15.6101 12.3151C15.6326 9.97134 15.3964 7.62759 14.8939 5.32509C14.7551 4.68384 14.0576 4.33134 13.4576 4.60134C11.9426 5.21259 10.5664 6.11259 9.39639 7.24134C9.26139 7.37259 9.05514 7.38759 8.90514 7.27134L5.86764 4.90134C5.48139 4.60134 4.91139 4.60134 4.57389 4.95759C4.18389 5.36634 4.24014 6.00759 4.67139 6.34509L18.3551 17.0288C18.5276 17.1638 18.7301 17.2276 18.9326 17.2276C19.2551 17.2276 19.5739 17.0626 19.7539 16.7438C19.9864 16.3351 19.8476 15.8138 19.4764 15.5251L19.4801 15.5213Z" />
    </svg>
  );
};
