import React, { CSSProperties } from "react";
import { Flex } from "@src/components/Boxes";

type FlexProps = React.ComponentProps<typeof Flex>;

export type ColumnProps = Omit<FlexProps, "flexDirection">;

export const Column = (props: ColumnProps) => <Flex flexDirection="column" {...props} />;

export type RowProps = Omit<FlexProps, "flexDirection">;

export const Row = (props: RowProps) => <Flex flexDirection="row" {...props} />;

export type StackProps = Omit<React.ComponentProps<typeof Flex>, "flexDirection"> & {
  flexDirection: [CSSProperties["flexDirection"], CSSProperties["flexDirection"]];
};

export const Stack = (props: StackProps) => <Flex {...props} />;
