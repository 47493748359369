import Container from "@src/components/Container";
import MentorPage from "@src/components/partials/new-mentors/page";
import { MentorPageProvider } from "@src/components/partials/new-mentors/provider";
import SEO from "@src/components/SEO";
import React from "react";

const Page = () => (
  <Container>
    <SEO title="directus.new_mentor.seo_title" description="directus.new_mentor.seo_description" />
    <MentorPageProvider>
      <MentorPage />
    </MentorPageProvider>
  </Container>
);

export default Page;
