import React from "react";
// import { useImages } from "../useImages";
// import { useTranslations } from "../useTranslations";
import { Body, Display, Headline, Label, PrimaryButton, SecondaryButton, Image, FlexBackground } from "./plain";
import { useImages, useTranslations } from "./usePage";
// import { useTranslations } from "./usePage";

export const withTranslationInValue = <T,>(WrappedComponent: React.FC<T>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const Wrapper: React.FC<Omit<T, "value"> & { textKey: string }> = (props) => {
    const t = useTranslations();

    const { textKey, ...rest } = props;

    return <WrappedComponent {...(rest as T)} value={t[textKey]} />;
  };

  Wrapper.displayName = `withTheme(${displayName})`;

  return Wrapper;
};

export const withImageInImg = <T,>(WrappedComponent: React.FC<T>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const Wrapper: React.FC<Omit<T, "img"> & { imgKey: string }> = (props) => {
    const i = useImages();
    const { imgKey, ...rest } = props;

    return <WrappedComponent {...(rest as T)} img={i[imgKey]} />;
  };

  Wrapper.displayName = `withTheme(${displayName})`;

  return Wrapper;
};

const XLabel = withTranslationInValue(Label);
const XBody = withTranslationInValue(Body);
const XDisplay = withTranslationInValue(Display);
const XHeadline = withTranslationInValue(Headline);

export const t = {
  Label: XLabel,
  Body: XBody,
  Display: XDisplay,
  Headline: XHeadline,
};

const XPrimaryButton = withTranslationInValue(PrimaryButton);
const XSecondaryButton = withTranslationInValue(SecondaryButton);

export const b = {
  PrimaryButton: XPrimaryButton,
  SecondaryButton: XSecondaryButton,
};

const XImage = withImageInImg(Image);
const XFlexBackground = withImageInImg(FlexBackground);

export const i = {
  Image: XImage,
  FlexBackground: XFlexBackground,
};
